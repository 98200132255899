/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef } from 'react';
import * as router from 'react-router-dom';

import { connect, useDispatch } from 'react-redux';

import * as _ from 'lodash';

import {
  accountPortfolioRequest,
  clientTokenUnset,
  defaultAccountSet,
  listAccountRequest,
  modePriceboardSet,
  setLogin,
} from 'containers/client/actions';
import { unsetRegSymbol } from 'containers/socket/actions';
import {
  makeGetAccountInfo,
  makeGetClientSetting,
  makeGetConfig,
  makeGetIndustryList,
  makeGetSocketStatus,
  makeGetToast,
  makeGetToken,
  makeGetTypeNav,
} from 'lib/selector';
import {
  getCategory,
  getCategoryNganhStorage,
  getCategoryPortfolioStorage,
  storages,
} from 'lib/storages';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast as notify } from 'react-toastify';
import Loading from 'shared/loading';
import NavLeft from 'shared/navLeft';
import {
  allStockRequest,
  allStockRightRequest,
  dmNganhRequest,
} from '../banggia/actions';

import { WebSocketContext } from '../socket/webSocket';
import { WindowContext } from 'shared/windowActive';

import { checkDefaultAuthorization } from 'lib/check-auth';
import Tooltiped from 'shared/tooltiped';

import ToastError from 'assets/img/toast/error.png';
import ToastInfo from 'assets/img/toast/info.png';
import ToastSuccess from 'assets/img/toast/success.png';
import ToastWarning from 'assets/img/toast/warrning.png';
import RenderContentToast from 'shared/ToastContent';

const DefaultHeader = React.lazy(() => import('./DefaultHeader'));
const DefaultFooter = React.lazy(() => import('./DefaultFooter'));

const Banggia = React.lazy(() => import('../banggia'));
const UpdateSoon = React.lazy(() => import('../../shared/updateSoon'));
const Trading = React.lazy(() => import('../trading'));
const TaiSan = React.lazy(() => import('../asset'));
const Extension = React.lazy(() => import('../extension'));
const Portfolio = React.lazy(() => import('../portfolio'));
const Right = React.lazy(() => import('../right'));
const Invest = React.lazy(() => import('../invest'));
const Cash = React.lazy(() => import('../cash'));
const DebtManagement = React.lazy(() => import('../debt-management'));
const Confirm = React.lazy(() => import('../confirm'));

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <router.Route
      {...rest}
      render={(props) => {
        checkDefaultAuthorization(rest);
        return <Component {...props} store={rest.dispatch.store} />;
      }}
    />
  );
}

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function DefaultLayout(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = router.useLocation();
  const navigate = router.useHistory();
  const [timeToLive] = React.useState(120);

  const ws = React.useContext(WebSocketContext);
  const wc = React.useContext(WindowContext);

  const {
    token,
    toast,
    reqLogin,
    typeNav,
    regSym,
    config,
    settings,
    industryList,
    accPortfolio,
    accountInfo,
    socketReady,
    serverStatus,
  } = props;

  const prevToast = usePrevious(toast);
  const preToken = usePrevious(token);
  const preAccountInfo = usePrevious(accountInfo);
  const prePathName = usePrevious(pathname);
  const preIndustryList = usePrevious(industryList);
  const preAccPortfolio = usePrevious(accPortfolio);
  const preWindowIsActive = usePrevious(wc?.windowIsActive);

  let logoutTimeout;

  console.log('serverStatus', serverStatus);

  useEffect(() => {
    //Tạm thời remove cho các máy
    // storages.removeState('category');

    ws.init();
    getCategory(props);
    const mode = localStorage.getItem('mode') || 'STANDARD';
    if (mode) dispatch(modePriceboardSet(mode));

    dispatch(allStockRequest());
    dispatch(allStockRightRequest());
    dispatch(dmNganhRequest());
    const _token = storages.loadState('token');

    if (!_token) {
      navigate.push('/price/bang-gia/vn30');
    }

    window.addEventListener('beforeunload', (ev) => {
      ev.preventDefault();
      storages.saveState('ttl', new Date().getTime());
    });
  }, []);

  useEffect(() => {
    if (pathname && !_.isEqual(pathname, prePathName)) {
      const storedToken = storages.loadState('token');

      if (pathname.indexOf('/price/bang-gia') < 0) {
        if (regSym) {
          // leave data
          const payload = {
            action: 'leave',
            data: regSym,
          };
          ws.sendMessage(payload);
          dispatch(unsetRegSymbol());
        }

        // if (!token) navigate.push('/price/bang-gia/vn30');
      }
      if (pathname === '/price/bang-gia/vn30/mo-tk-to-bang-gia') {
        navigate.push('/price/bang-gia/vn30');
        if (!storedToken) dispatch(setLogin());
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (
      !token &&
      token !== undefined &&
      preToken &&
      !_.isEqual(token, preToken)
    ) {
      clearTimeoutFunc();
      console.log('token', token, preToken);
      if (pathname.indexOf('/price/bang-gia') < 0) {
        // if (!showLossSessionModal) {
        //   setShowLossSessionModal(true);
        // }
        navigate.push('/price/bang-gia/vn30');
      }
    }

    if (token && !_.isEqual(token, preToken)) {
      const ttl = storages.loadState('ttl') || new Date().getTime(),
        now = new Date().getTime();
      const diffMinute = (now - ttl) / (1000 * 60);
      console.log(diffMinute);
      if (diffMinute > timeToLive) {
        _actionTimeToLive();
        return;
      }

      dispatch({ type: 'INVALID_SESSION_CLEAR' });
      // dispatch(defaultAccountSet(token.defaultAcc));
      // load list account
      getListAccount();
      getListPortfolio();
      const events = [
        // 'load',
        // 'mousemove',
        // 'mousedown',
        'click',
        'scroll',
        'keypress',
      ];
      for (var i in events) {
        window.addEventListener(events[i], resetTimeout);
      }

      setTimeOut();
    }
  }, [token]);

  useEffect(() => {
    console.log(wc);
    if (
      wc?.windowIsActive &&
      preWindowIsActive === false &&
      !_.isEqual(wc?.windowIsActive, preWindowIsActive)
    ) {
      if (!socketReady) {
        ws.init();
      }
      if (socketReady && serverStatus === 'off') {
        ws.reconnectTrigger();
      }
    }
  }, [wc?.windowIsActive]);

  useEffect(() => {
    if (accountInfo && !_.isEqual(accountInfo, preAccountInfo)) {
      dispatch(defaultAccountSet(accountInfo?.C_ACCOUNT_DEFAULT));
    }
  }, [accountInfo]);

  useEffect(() => {
    if (toast && toast.msg && !_.isEqual(toast, prevToast)) {
      if (toast.type) {
        notify[toast.type](<RenderContentToast toast={toast} />, {
          icon: ({ type }) => (
            <img
              src={
                type === 'success'
                  ? ToastSuccess
                  : type === 'error'
                    ? ToastError
                    : type === 'warning'
                      ? ToastWarning
                      : ToastInfo
              }
            />
          ),
        });
      } else
        notify.info(toast.msg?.message || toast.msg, {
          icon: () => <img src={ToastInfo} />,
        });
      dispatch({ type: 'CLEAR_TOAST' });
    }
  }, [toast]);

  useEffect(() => {
    if (industryList && !_.isEqual(industryList, preIndustryList)) {
      getCategoryNganhStorage(props);
    }
  }, [industryList]);

  useEffect(() => {
    if (accPortfolio && !_.isEqual(accPortfolio, preAccPortfolio)) {
      getCategoryPortfolioStorage(props);
    }
  }, [accPortfolio]);

  // const Logout = () => {
  //   dispatch(clientTokenUnset())
  //   dispatch(unsetLogin())
  //   storages.removeState('token')
  //   storages.removeState('ttl')

  //   setTimeout(() => {
  //     // navigate.push('/login');
  //     dispatch(setLogin())
  //   }, 100)
  // }

  const resetTimeout = () => {
    // console.log('resetTimeout');
    clearTimeoutFunc();
    // setTimeOut()
  };

  const clearTimeoutFunc = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  const setTimeOut = () => {
    logoutTimeout = setTimeout(_actionTimeToLive, timeToLive * 60 * 1000);
  };

  const _actionTimeToLive = () => {
    console.log('_actionTimeToLive');
    dispatch({ type: 'INVALID_SESSION' });

    dispatch(clientTokenUnset());
    storages.removeState('token');
    storages.removeState('ttl');
  };

  const getListAccount = () => {
    const params = {
      group: 'B',
      user: token.user,
      session: token.sid,
      data: {
        type: 'cursor',
        cmd: 'ListAccount',
      },
    };

    // request list account
    dispatch(listAccountRequest(params));
  };

  const getListPortfolio = () => {
    if (!token) return;
    const params = {
      group: 'B',
      user: token?.user,
      session: token?.sid,
      data: {
        type: 'cursor2',
        cmd: 'AccountPorfolio',
      },
    };

    // request list account portfolio
    dispatch(accountPortfolioRequest(params));
  };

  return (
    <div className="app overflow-hidden h-screen bg-skin-body text-skin-base w-screen relative">
      <div
        className={
          'w-full ' +
          (typeNav === 'minimize'
            ? 'grid grid-cols-[68px_calc(100vw-68px)]'
            : typeNav === 'hover'
              ? 'relative grid grid-cols-[68px_calc(100vw-68px)]'
              : typeNav === 'hidden'
                ? 'relative '
                : 'grid grid-cols-[180px_calc(100vw-180px)]')
        }
      >
        <NavLeft typeNav={typeNav} />
        <div className={'app-body h-screen w-full '}>
          <Suspense fallback={<Loading />}>
            <DefaultHeader token={token} />
          </Suspense>
          <main className="main w-full relative">
            <div className="h-full w-full">
              <Suspense fallback={<Loading />}>
                <router.Switch>
                  <PrivateRoute
                    path={'/price/bang-gia/:categoryId'}
                    component={Banggia}
                    dispatch={props}
                  />
                  <PrivateRoute
                    path={['/price/order']}
                    component={Trading}
                    dispatch={props}
                  />
                  <PrivateRoute
                    path="/price/portfolio"
                    component={Portfolio}
                    dispatch={props}
                  />
                  <PrivateRoute
                    path="/price/debt"
                    component={DebtManagement}
                    dispatch={props}
                  />
                  <PrivateRoute
                    path="/price/right"
                    component={Right}
                    dispatch={props}
                  />

                  <PrivateRoute
                    path="/price/confirm"
                    component={Confirm}
                    dispatch={props}
                  />

                  <PrivateRoute
                    path="/invest"
                    component={Invest}
                    dispatch={props}
                  />
                  <PrivateRoute
                    path={[
                      '/analysis',
                      '/trading',
                      '/search',
                      '/ho-tro',
                      '/khao-sat',
                      '/account',
                      '/sms',
                      '/help',
                      '/contact',
                    ]}
                    component={UpdateSoon}
                    dispatch={props}
                  />
                  <PrivateRoute
                    path="/asset"
                    component={TaiSan}
                    dispatch={props}
                  />
                  <PrivateRoute
                    path={'/extension/:typeId?'}
                    component={Extension}
                    dispatch={props}
                  />
                  <PrivateRoute
                    path={'/cash/:typeId?'}
                    component={Cash}
                    dispatch={props}
                  />

                  <router.Redirect from="/price" to="/price/bang-gia/vn30" />
                  <router.Redirect
                    from="/price/bang-gia"
                    to="/price/bang-gia/vn30"
                  />
                  <router.Redirect from="/bang-gia" to="/price/bang-gia/vn30" />
                </router.Switch>
              </Suspense>
            </div>
          </main>
        </div>
      </div>
      <Suspense fallback={<Loading />}>
        <DefaultFooter token={token} />
      </Suspense>
      <ToastContainer
        closeButton={false}
        // pauseOnFocusLoss
        // pauseOnHover
        // autoClose={200000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
      <Tooltiped id="global" />
    </div>
  );
}

const makeMapStateToProps = () => {
  const getToken = makeGetToken();
  const getConfig = makeGetConfig();
  const getClientSetting = makeGetClientSetting();
  const getToast = makeGetToast();
  const getTypeNav = makeGetTypeNav();
  const getIndustryList = makeGetIndustryList();
  const getAccountInfo = makeGetAccountInfo();
  const getSocketStatus = makeGetSocketStatus();
  const mapStateToProps = (state) => {
    return {
      token: getToken(state),
      config: getConfig(state),
      settings: getClientSetting(state),
      toast: getToast(state),
      typeNav: getTypeNav(state),
      industryList: getIndustryList(state),
      accPortfolio: state.client.accPortfolio,
      accountInfo: getAccountInfo(state),
      regSym: state.socket.regSym,
      reqLogin: state.client.reqLogin,
      showNav: state.client.showNav,
      socketReady: state.socket.socketReady,
      serverStatus: getSocketStatus(state),
    };
  };
  return mapStateToProps;
};

export default React.memo(connect(makeMapStateToProps)(DefaultLayout));
