/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

interface Props {
  input: any;
  disabled: boolean | undefined;
  meta: any;
}

const RenderCheckbox: React.FunctionComponent<Props> = (props) => {
  const {
    input,
    meta: { touched, error },
  } = props;

  return (
    <div {...input} className=" mt-5">
      <div
        className="border rounded"
        style={{
          borderColor: `${touched && error ? '#FF0000' : 'transparent'}`,
        }}
      >
        <label className="self-start mb-0 text-skin-white text-sm my-5 select-none">
          <input
            type="checkbox"
            className="w-4 h-4 border-[var(--color-thumb)] text-skin-text6 rounded bg-transparent "
          />{' '}
          Tôi đồng ý với{' '}
          <a href="#" className="text-skin-white underline">
            điều khoản
          </a>
        </label>
      </div>
      {touched && error && (
        <div className='text-sm text-skin-red mt-1'>
          {`Bạn phải đồng ý với điều khoản và điều kiện`}
        </div>
      )}
    </div>
  );
};

export default RenderCheckbox;
