/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import IconLogo from 'assets/img/logo/logo.svg';
import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as router from 'react-router-dom';
import Loading from 'shared/loading';

import ForgotPass from 'containers/forgot-pass';
import Login from 'containers/login';
import Register from 'containers/register';
import { usePrevious } from 'lib/useHook';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { toast as notify } from 'react-toastify';
import * as _ from 'lodash';
import { makeGetToast } from 'lib/selector';
import ToastSuccess from 'assets/img/toast/success.png';
import ToastWarning from 'assets/img/toast/warrning.png';
import ToastError from 'assets/img/toast/error.png';
import ToastInfo from 'assets/img/toast/info.png';
import RenderContentToast from 'shared/ToastContent';

import FooterAuthentication from './FooterAuthentication';
import PerfectScrollbar from 'react-perfect-scrollbar';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <router.Route
      {...rest}
      render={(props) => {
        return <Component {...props} store={rest.dispatch.store} />;
      }}
    />
  );
}

function DefaultAuthentication(props) {
  const { t } = useTranslation();
  const location = router.useLocation();
  const dispatch = useDispatch();
  const { toast } = props;

  const prevToast = usePrevious(toast);

  React.useEffect(() => {
    if (toast && toast.msg && !_.isEqual(toast, prevToast)) {
      if (toast.type) {
        notify[toast.type](<RenderContentToast toast={toast} />, {
          icon: ({ type }) => (
            <img
              src={
                type === 'success'
                  ? ToastSuccess
                  : type === 'error'
                    ? ToastError
                    : type === 'warning'
                      ? ToastWarning
                      : ToastInfo
              }
            />
          ),
        });
      } else
        notify.info(toast.msg?.message || toast.msg, {
          icon: () => <img src={ToastInfo} />,
        });
      dispatch({ type: 'CLEAR_TOAST' });
    }
  }, [toast]);
  return (
    <div className="app overflow-hidden h-screen bg-skin-body text-skin-base w-screen pt-6 px-6">
      <img src={IconLogo} alt="IMG LOGO" />
      <div
        className={
          'app-body w-full ' +
          (location.pathname !== '/register'
            ? 'h-[calc(100vh-110px)]'
            : 'h-full')
        }
      >
        <PerfectScrollbar className="h-full">
          <div className="w-full h-full">
            <Suspense fallback={<Loading />}>
              <router.Switch>
                <PrivateRoute
                  path={'/login'}
                  component={Login}
                  dispatch={props}
                />
                <PrivateRoute
                  path={'/forgot'}
                  component={ForgotPass}
                  dispatch={props}
                />
                <PrivateRoute
                  path={'/register'}
                  component={Register}
                  dispatch={props}
                />
              </router.Switch>
            </Suspense>
          </div>
        </PerfectScrollbar>
      </div>
      {location.pathname !== '/register' && <FooterAuthentication t={t} />}
      <ToastContainer pauseOnHover={false} pauseOnFocusLoss />
    </div>
  );
}

const makeMapStateToProps = () => {
  const getToast = makeGetToast();

  const mapStateToProps = (state) => {
    return {
      toast: getToast(state),
    };
  };
  return mapStateToProps;
};

export default React.memo(connect(makeMapStateToProps)(DefaultAuthentication));
