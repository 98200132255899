/* eslint-disable jsx-a11y/anchor-is-valid */
import _ from 'lodash';
import React from 'react';
import { AppState } from 'reducers';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { regAccountSuccess, regCustTempSet } from 'containers/register/actions';
import { makeGetRegAccount, makeGetRegCustTemp } from 'lib/selector';
import RegisStep1 from './layout/regisStep1';
import RegisStep2 from './layout/regisStep2';
import RegisterSuccessModal from 'components/modal/registerSuccess';

interface Props {
  isLoading?: boolean;
  errorMsg?: Error | string | null;
  clearChangePass: Function;
  regAccount?: number | null;
  regCustTemp?: any;
}

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function RegisterComponent(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [step, setStep] = React.useState<number>(1);
  const [isShowSuccess, setIsShowSuccess] = React.useState<boolean>(false);
  const { clearChangePass, regAccount, regCustTemp } = props;

  const preRegAccount = usePrevious(regAccount);

  React.useEffect(() => {
    return () => {
      dispatch(regAccountSuccess(null));
      dispatch(regCustTempSet(null));
      dispatch({ type: 'res/CHECK_CUST_CLEAR' });
      dispatch({ type: 'CLEAR_TOAST' });
    };
  }, []);

  React.useEffect(() => {
    if (regAccount && !_.isEqual(regAccount, preRegAccount)) {
      setIsShowSuccess(true);
    }
  }, [regAccount]);

  function handleClose() {
    setIsShowSuccess(false);
    clearChangePass();
  }

  return (
    <div className="h-full flex">
      {step === 1 && (
        <RegisStep1 setStep={setStep} returnLogin={handleClose} t={t} />
      )}
      {step === 2 && (
        <RegisStep2 setStep={setStep} returnLogin={handleClose} t={t} />
      )}
      {isShowSuccess && (
        <RegisterSuccessModal
          onClose={() => handleClose()}
          accountCode={regAccount}
          data={regCustTemp}
        />
      )}
    </div>
  );
}

const makeMapStateToProps = () => {
  const getRegAccount = makeGetRegAccount();
  const getRegCustTemp = makeGetRegCustTemp();

  const mapStateToProps = (state: AppState) => {
    return {
      regAccount: getRegAccount(state),
      regCustTemp: getRegCustTemp(state),
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(RegisterComponent);
