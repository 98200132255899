/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import _ from 'lodash';

import { clientTokenSet } from '../client/actions';
import { useHistory } from 'react-router';
import { makeGetConfig } from 'lib/selector';
import { AppState } from 'reducers';
import { storages } from 'lib/storages';

import BgGrid from 'assets/img/bg/authen/bg-grid.svg';
import BgStepLeft from 'assets/img/bg/authen/bg-step-left.svg';
import BgTextRegister from 'assets/img/bg/authen/txt-register.svg';
import { ReactComponent as IconChecked } from 'assets/img/icon/icChecked.svg';
import { useTranslation } from 'react-i18next';
import { handleApiErrors } from 'lib/api-error';
import { setToast } from 'containers/client/actions';

function usePrevious(value: any): any {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

interface Props {
  successful: any;
}

function RegisterPage(props: Props): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const { t } = useTranslation();

  const [step, setStep] = useState<number>(1);
  const { successful } = props;

  const preSuccessful = usePrevious(successful);

  useEffect(() => {
    if (successful && !_.isEqual(successful, preSuccessful)) {
      const token = {
        user: successful.USER_NAME,
        session: successful.SESSION_ID,
        info: successful.CMDS,
        serverType: 'Prod',
      };

      // dispatch action to store
      dispatch(clientTokenSet(token));

      storages.saveState('token', token);
      navigate.push('/price');
    }
  }, [successful]);

  function _handleToast(msg: string, type = 'info') {
    const toastMsg = {
      id: Math.random(),
      msg: msg,
      title: t('Thông báo'),
      type,
    };
    dispatch(setToast(toastMsg));
  }

  return (
    <div className="h-full grid md:grid-cols-3 gap-6">
      <div
        className="font-briGro w-full overflow-hidden h-full"
        style={{
          backgroundImage: `url(${BgGrid})`,
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="text-skin-subdued text-2xl md:mt-10">
          {t('account.register.regis')}
        </div>
        <img src={BgTextRegister} alt="" className="h-12" />
        <div className="bg-skin-active w-0.5 h-[25px] mt-4" />
        <div
          className="w-[337px] h-[653px] flex items-center"
          style={{
            backgroundImage: `url(${BgStepLeft})`,
            backgroundPosition: 'left',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
          }}
        >
          <ul className="text-skin-text3 font-hanGro md:my-6 text-lg ms-[60px]">
            <li
              className={
                'relative h-[68px] ' + (step >= 1 ? 'w-[283px]' : 'w-[215px]')
              }
            >
              <div
                className={
                  'w-full h-full border border-skin-weak bg-skin-inverse-white pl-9 flex items-center relative rounded-sm ' +
                  (step === 1
                    ? 'text-skin-up text-2xl font-semibold font-briGro'
                    : 'opacity-50')
                }
              >
                {t('account.register.basic-infor')}
                <div className="bg-skin-primary w-12 h-12 rounded-full absolute -left-9 top-1/2 -translate-y-1/2 flex items-center justify-center">
                  <div className="w-[34px] h-[34px] bg-skin-natural-6 rounded-full"></div>
                </div>
              </div>
              <div className="w-12 h-12 rounded-full absolute -left-9 top-1/2 -translate-y-1/2 flex items-center justify-center">
                <div className="bg-skin-natural-7 w-[26px] h-[26px] rounded-full text-skin-white text-lg flex items-center justify-center">
                  {step > 1 ? (
                    <div className="w-[21px] h-[21px] rounded-full bg-skin-active flex items-center justify-center">
                      <IconChecked className="opacity-75 stroke-white" />
                    </div>
                  ) : (
                    1
                  )}
                </div>
              </div>
            </li>

            <li
              className={
                'relative mt-[60px] h-[68px] ' +
                (step >= 2 ? 'w-[283px]' : 'w-[215px]')
              }
            >
              <div
                className={
                  'w-full h-full border border-skin-weak bg-skin-inverse-white pl-9 flex items-center relative rounded-sm ' +
                  (step === 2
                    ? 'text-skin-up text-2xl font-semibold font-briGro'
                    : 'opacity-50')
                }
              >
                {t('account.register.res-service')}

                <div className="bg-skin-primary w-12 h-12 rounded-full absolute -left-9 top-1/2 -translate-y-1/2 flex items-center justify-center">
                  <div className="w-[34px] h-[34px] bg-skin-natural-6 rounded-full"></div>
                </div>
              </div>
              <div className="w-12 h-12 rounded-full absolute -left-9 top-1/2 -translate-y-1/2 flex items-center justify-center">
                <div className="bg-skin-natural-7 w-[26px] h-[26px] rounded-full text-skin-white text-lg flex items-center justify-center">
                  {step > 2 ? (
                    <div className="w-[21px] h-[21px] rounded-full bg-skin-active flex items-center justify-center">
                      <IconChecked className="opacity-75" />
                    </div>
                  ) : (
                    2
                  )}
                </div>
              </div>
            </li>
            <li
              className={
                'relative mt-[60px] h-[68px] ' +
                (step === 3 ? 'w-[283px]' : 'w-[215px]')
              }
            >
              <div
                className={
                  'w-full h-full border border-skin-weak bg-skin-inverse-white pl-9 flex items-center relative rounded-sm ' +
                  (step === 3
                    ? 'text-skin-up text-2xl font-semibold font-briGro'
                    : 'opacity-50')
                }
              >
                {t('account.register.sign-contract')}

                <div className="bg-skin-primary w-12 h-12 rounded-full absolute -left-9 top-1/2 -translate-y-1/2 flex items-center justify-center">
                  <div className="w-[34px] h-[34px] bg-skin-natural-6 rounded-full"></div>
                </div>
              </div>
              <div className="w-12 h-12 rounded-full absolute -left-9 top-1/2 -translate-y-1/2 flex items-center justify-center">
                <div className="bg-skin-natural-7 w-[26px] h-[26px] rounded-full text-skin-white text-lg flex items-center justify-center">
                  3
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="md:col-span-2 grid gap-4 auto-rows-max">
        {step === 1 && (
          <RegisterStep1
            handleNextStep={_handleNextStep}
            handlePreSubStep={_handlePreSubStep}
            handleNextSubStep={_handleNextSubStep}
            handleGetSid={handleGetSid}
            sid={sid}
            subStep={subStep}
            listCity={optsProvince}
            handleGetValueStep1={_handleGetValueStep1}
          />
        )}
        {step === 2 && (
          <RegisterStep2
            handleNextStep={_handleNextStep}
            handlePreStep={_handlePreStep}
            handlePreSubStep={_handlePreSubStep}
            handleNextSubStep={_handleNextSubStep}
            subStep={subStep}
            listCity={optsProvince}
            nameAccBen={valuesStep1?.formFullName}
            nameCCCD={valuesStep1?.data?.fullName}
            handleGetValueStep2={_handleGetValueStep2}
            sid={sid}
          />
        )}
        {step === 3 && (
          <RegisterStep3
            handleNextStep={_handleNextStep}
            handlePreStep={_handlePreStep}
            handlePreSubStep={_handlePreSubStep}
            handleNextSubStep={_handleNextSubStep}
            handleOpenAcc={openAccount}
            valueRes={valuesRes}
            subStep={subStep}
          />
        )}
      </div> */}
    </div>
  );
}

const makeMapStateToProps = () => {
  const getConfig = makeGetConfig();

  const mapStateToProps = (state: AppState) => {
    return {
      config: getConfig(state),

      successful: state.login.successful,
    };
  };
  return mapStateToProps;
};

export default connect(makeMapStateToProps)(RegisterPage);
