import * as CryptoJS from 'crypto-js';
import MD5 from 'crypto-js/md5';
import i18n from 'i18next';
import { storages } from 'lib/storages';
import _ from 'lodash';
import { ParamModel } from '../interface';
interface IErrMsg {
  [key: string]:
    | {
        msg: string;
      }
    | undefined;
}

export const errMsg: IErrMsg = {
  '-6004': { msg: 'Lệnh đã được xử lý, không được gửi lại.' },
  '-6007': { msg: 'Hủy lệnh không thành công.' },
  '-6012': { msg: 'Tài khoản không đủ sức mua.' },
  '-6013': { msg: 'Không đủ số dư chứng khoán.' },
  '-6014': { msg: 'Lệnh không được hủy.' },
  '-6015': { msg: 'Không được sửa lệnh này.' },
  '-6016': { msg: 'Loại chứng khoán không hợp lệ.' },
  '-6017': { msg: 'Tài Khoản đang chờ kích hoạt.' },
  '-6019': { msg: 'Khối lượng đặt không hợp lệ.' },
  '-6020': { msg: 'Không xác định được thị trường.' },
  '-6021': { msg: 'Giá không phù hợp.' },
  '-6022': { msg: 'Hết giờ giao dịch.' },
  '-6023': { msg: 'Loại lệnh không phù hợp.' },
  '-6024': { msg: 'Không được đặt loại lệnh phiên này.' },
  '-6025': { msg: 'Hệ thống chưa sẵn sàng đặt lệnh ngoài giờ.' },
  '-6026': { msg: 'Không được huỷ/sửa lệnh phiên này.' },
  '-6027': { msg: 'Không được đặt lệnh điều kiện.' },
  '-6028': { msg: 'Tài khoản không được mua - bán.' },
  '-6029': { msg: 'Tài khoản không được ủy quyền.' },
  '-6030': { msg: 'User không có quyền xem tài khoản.' },
  '-6031': { msg: 'Chứng khoán không hợp lệ.' },
  '-6032': { msg: 'Vượt qua tỷ lệ an toàn tài khoản.' },
  '-6033': { msg: 'Chứng khoán đáo hạn không được giao dịch.' },
  '-6034': { msg: 'Vượt qua khối lượng 1000 của phái sinh.' },
  '-6035': { msg: 'Sai giá trần/sàn.' },
  '-6036': { msg: 'Sai bước giá cho trái phiếu.' },
  '-6037': { msg: 'Không được đặt lệnh.' },
  '-6038': { msg: 'Vượt qua tổng vị thế tối đa.' },
  '-6039': { msg: 'Tài khoản mới mở, chưa được giao dịch.' },
  '-6041': { msg: 'Tài khoản hạn chế giao dịch.' },
  '-6043': { msg: 'Không được mua bán cùng phiên.' },
  '-6045': { msg: 'Chứng khoán hết room cho vay' },
  '-6046': { msg: 'Chứng khoán hết room nước ngoài' },
  '-6047': { msg: 'Hết hạn mức mã chứng khoán ' },
  '-6048': { msg: 'Không được sửa đồng thời giá và khối lượng' },
  '-6666': { msg: 'Hết hạn mức nguồn.' },
  '-6667': { msg: 'Hết hạn mức tài khoản.' },
  '-6668': { msg: 'Tài khoản hạn chế bán do QTRR.' },
  '-6669': { msg: 'Lệnh forcesell, KH không được thao tác.' },
  '-6670': { msg: 'Hết hạn mức tổng của nhóm.' },
  '-6671': { msg: 'Hết hạn mức công ty.' },

  '-8001': { msg: 'TRADER HALT' },
  '-8002': { msg: 'Thị trường đóng của, không được đặt lệnh.' },
  '-8003': { msg: 'Không hủy/sửa phiên khớp lệnh định kỳ.' },
  '-8004': { msg: 'Lệnh đã khớp hết, không được hủy/sửa.' },
  '-8005': { msg: 'Sai mã PIN.' },
  '-8006': { msg: 'Không dùng password cũ.' },
  '-8007': { msg: 'Password không hợp lệ.' },
  '-8008': { msg: 'Không có quyền truy cập từ IP ngoài.' },
  '-8009': { msg: 'TRADER HALT.' },
  '-8010': { msg: 'Hệ thống đang thi với HSX, KHÔNG ĐƯỢC ĐẶT LỆNH.' },
  '-8011': { msg: 'BrokerID đang bị chặn MUA.' },
  '-8012': { msg: 'BrokerID đang bị chặn BÁN.' },
  '-8013': { msg: 'BrokerID đang bị chặn giao dịch.' },
  '-8014': { msg: 'BrokerID bị chặn giao dịch thoả thuận.' },
  '-8015': { msg: 'BrokerID bị chặn giao dịch thoả thuận BÁN.' },
  '-8016': { msg: 'BrokerID bị chặn giao dịch thoả thuận MUA.' },
  '-9998': { msg: 'Lệnh chưa được confirm!' },
  '-9999': { msg: 'Không đủ điều kiện sửa.' },
  '-9001': { msg: 'Không được giao dịch mã chứng quyền trên TK margin' },
  '-9501': {
    msg: 'Giá trị giao dịch vượt quá giá trị giao dịch cho phép trong ngày/tháng',
  },
  '-9502': {
    msg: 'Khối lượng/loại lệnh không đúng với khối lượng/loại lệnh đã đăng ký',
  },
  '-8': { msg: 'Hệ thống chưa sẵn sàng nhận lệnh' },
  '-111': { msg: 'Không kết nối được hệ thống.' },

  '0': { msg: 'Thành công' },
  '210': { msg: 'Giấy tờ bị cắt góc' },
  '211': { msg: 'Không tìm thấy dấu quốc huy' },
  '212': { msg: 'Ảnh chụp giấy tờ bị loá' },
  '213': { msg: 'Không tìm thấy ảnh chân dung' },
  '214': { msg: 'Ảnh chân dung có dấu hiệu bị thay thế' },
  '215': { msg: 'Ảnh chụp giấy tờ là bản photo' },
  '216': { msg: 'Có trường thông tin bị chỉnh sửa' },
  '217': { msg: 'Ảnh chụp giấy tờ không phải bản gốc' },
  '218': { msg: 'Ảnh chụp giấy tờ nằm ngoài khung hình' },
  '219': { msg: 'Không tìm thấy dấu đỏ' },
  '220': { msg: 'Không tìm thấy vân tay phải' },
  '221': { msg: 'Không tìm thấy vân tay trái' },
  '222': { msg: 'Điểm trích xuất chính xác số CCCD thấp' },
  '223': { msg: 'Điểm trích xuất chính xác họ tên thấp' },
  '224': { msg: 'Điểm trích xuất chính xác ngày hết hạn thấp' },
  '225': { msg: 'Điểm trích xuất chính xác ngày hết hạn thấp' },
  '226': { msg: 'Loại giấy tờ mặt trước không khớp với mặt sau' },
  '227': { msg: 'Số giấy tờ không đúng định dạng' },
  '228': { msg: 'Lỗi nhận diện giấy tờ' },
  '229': { msg: 'Ảnh chụp không đạt yêu cầu' },
  '310': { msg: 'Vui lòng chuyển động gương mặt chậm' },
  '311': { msg: 'Không phát hiện chuyển động' },
  '312': { msg: 'Khuôn mặt không khớp trong quá trình liveness' },
  '313': { msg: 'Giả mạo xác thực' },
  '314': { msg: 'Vui lòng bỏ kính' },
  '315': { msg: 'Vui lòng bỏ khẩu trang' },
  '316': { msg: 'Vui lòng nhìn thẳng vào camera' },
  '317': { msg: 'Có nhiều khuôn mặt trong khung hình' },
  '330': { msg: 'Ảnh CCCD không khớp với ảnh liveness' },
  '331': { msg: 'Không lấy được ảnh để so sánh' },
  '332': { msg: 'Không tìm thấy ảnh khuôn mặt trong ảnh ID' },
  '333': { msg: 'Có nhiều khuôn mặt trong ảnh ID' },
  '334': { msg: 'Không tìm thấy ảnh khuôn mặt trong ảnh liveness' },
  '335': { msg: 'Có nhiều khuôn mặt trong ảnh liveness' },
  '500': { msg: 'Lỗi hệ thống ' },
  '408': { msg: 'Time out' },
  '1004': { msg: 'Mã OTP không đúng' },
  '1005': { msg: 'Tài khoản CA Cloud đã bị khóa do nhập mã OTP sai quá 4 lần' },
  '1013': { msg: 'Chứng thư số chưa sẵn sàng' },
  '1014': { msg: 'Chứng thư số hết hạn' },
  '1015': { msg: 'Mã OTP đã hết hạn, vui lòng lấy mã OTP mới' },
};

export function replaceAll(
  str: string | undefined,
  find: string,
  replace: string = '',
): string {
  if (!str) return '';

  return str.replace(new RegExp(find, 'g'), replace);
}

export function addZero(i: string) {
  if (Number(i) < 10) {
    i = '0' + i;
  }
  return i;
}

function pick(...manyMoreArgs: any[]) {
  let a = manyMoreArgs,
    c,
    r,
    u = a.length;
  for (c = 0; c < u; c++)
    if (((r = a[c]), void 0 !== r && null !== r)) return r;
}

export function numberFormat(
  h: string | number,
  c: number = 0,
  t: string = '',
  r: any = undefined,
  u: any = undefined,
) {
  if (h === 'ATO' || h === 'ATC') return h;
  h = +h || 0;
  c = +c;
  let w: number | string = (h.toString().split('.')[1] || '').split('e')[0]
      .length,
    n,
    g,
    d = h.toString().split('e');

  g = (
    Math.abs(d[1] ? Number(d[0]) : h) + Math.pow(10, -Math.max(c, w) - 1)
  ).toFixed(c);
  w = String(parseInt(g, 10));
  n = 3 < w.length ? w.length % 3 : 0;
  r = pick(r, '.');
  u = pick(u, ',');
  h = (0 > h ? '-' : '') + (n ? w.substr(0, n) + u : '');
  h += w.substr(n).replace(/(\d{3})(?=\d)/g, '$1' + u);
  c && (h += r + g.slice(-c));
  d[1] && 0 !== +h && (h += 'e' + d[1]);
  if (Number(h) == 0) return t;
  return h;
}

export function colorFix(
  cPrice: number | string,
  oPrice: number,
  tran: number,
  san: number,
  tc: number,
): string {
  if (typeof cPrice === 'string') {
    cPrice = StringToDouble(cPrice);
  }
  if (cPrice == 0) return 'preo';
  if (cPrice == tc) return 'r';
  if (cPrice == tran) return 'c';
  if (cPrice == san) return 'f';
  if (cPrice - oPrice > 0) {
    return 'i';
  } else if (cPrice - oPrice < 0) {
    return 'd';
  }
  return 'r';
}

export function StringToInt(pString: string | number | any): number {
  pString = '' + pString;
  pString = pString.replace(/,/g, '');
  let vInt = parseInt(pString, 10);
  if (isNaN(vInt)) {
    return 0;
  } else {
    return vInt;
  }
}

export function StringToDouble(pString: string | number | any): number {
  pString = '' + pString;
  pString = pString.replace(/,/g, '');
  //Convert sang so he so 10
  let vFloat = parseFloat(pString);
  if (isNaN(vFloat)) {
    return 0;
  } else {
    return vFloat;
  }
}

export function stringToDate(idata: string | null | undefined, format = 'ymd') {
  if (!idata) return null;

  let slack: string = '',
    slack1 = '/',
    slack2 = '-';

  if (idata.includes(slack1)) slack = slack1;
  if (idata.includes(slack2)) slack = slack2;

  // console.log("stringToDate", idata);
  try {
    const _date = idata.split(slack);
    if (format === 'ymd') {
      let y = _date[0];
      let m = addZero(_date[1]);
      let d = addZero(_date[2]);
      let st = new Date(y + '/' + m + '/' + d);
      // console.log(st);
      return st;
    } else {
      let y = _date[2];
      let m = addZero(_date[1]);
      let d = addZero(_date[0]);
      let st = new Date(y + '/' + m + '/' + d);
      // console.log(st);
      return st;
    }
  } catch (error) {
    return null;
  }
}

export function formatDate(
  idata: number | string | Date | null,
  slack = ':',
  _fm = 'ymd',
) {
  if (!idata) return;

  let y: number, m: string, d: string;

  // console.log('formatDate', idata);

  try {
    if (typeof idata === 'number' || typeof idata === 'string') {
      let st: any;
      if (typeof idata === 'number') st = new Date(idata);
      if (typeof idata === 'string') st = new Date(replaceAll(idata, '/', '-'));

      y = st.getFullYear();
      m = addZero(st.getMonth() + 1 + '');
      d = addZero(st.getDate() + '');
    } else {
      y = idata.getFullYear();
      m = addZero(idata.getMonth() + 1 + '');
      d = addZero(idata.getDate() + '');
    }
    if (_fm === 'ymd') {
      return y + slack + m + slack + d;
    } else {
      return d + slack + m + slack + y;
    }
  } catch (error) {
    return null;
  }
}

export function formatDateTime(idata: number | Date, slack = ':'): string {
  if (!idata) return '';

  try {
    if (typeof idata === 'number') {
      let st: Date = new Date(idata);
      let h = addZero(st.getHours() + '');
      let m = addZero(st.getMinutes() + '');
      let s = addZero(st.getSeconds() + '');
      return h + slack + m + slack + s;
    } else {
      let h = addZero(idata.getHours() + '');
      let m = addZero(idata.getMinutes() + '');
      let s = addZero(idata.getSeconds() + '');
      return h + slack + m + slack + s;
    }
  } catch (error) {
    return idata + '';
  }
}

export function formatDateDay(idata: number | Date, lang = 'en'): string {
  if (!idata) return '';

  try {
    const dayname = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];
    if (typeof idata === 'number') {
      let st: Date = new Date(idata);
      let m = addZero(st.getMonth() + 1 + '');
      let d = addZero(st.getDate() + '');
      let dw = st.getDay();
      return dayname[dw] + ', ' + d + ' tháng ' + m;
    } else {
      let m = addZero(idata.getMonth() + 1 + '');
      let d = addZero(idata.getDate() + '');
      let dw = idata.getDay();
      return dayname[dw] + ', ' + d + ' tháng ' + m;
    }
  } catch (error) {
    return idata + '';
  }
}

export function formatLocalDateTime(idata: Date): string {
  if (!idata) return '';

  const _hours = idata.getHours();
  const isPm = _hours > 12 ? 'PM' : 'AM';
  const isHour = _hours > 12 ? _hours - 12 : _hours;

  try {
    let h = addZero(isHour + '');
    let m = addZero(idata.getMinutes() + '');
    return h + ':' + m + ' ' + isPm + ', ' + formatDate(idata, '/', 'dmy');
  } catch (error) {
    return idata + '';
  }
}

export function formatMinutes(idata: number): string {
  if (!idata) return '';

  const _hours = Math.floor(idata / (60 * 60));
  const _minutes = Math.floor((idata - _hours * 3600) / 60);
  const _seconds = idata - _hours * 3600 - _minutes * 60;

  try {
    let h = addZero(_hours + '');
    let m = addZero(_minutes + '');
    let s = addZero(_seconds + '');
    return (_hours > 0 ? h + ':' : '') + m + ':' + s;
  } catch (error) {
    return idata + '';
  }
}

export function diff2Date(date1: string | Date, date2: string | Date) {
  if (!date1 || !date2) return -1;
  console.log(date1, date2);
  let mydate1: Date, mydate2: Date;
  if (typeof date1 === 'string') {
    let parts1 = replaceAll(date1, '-', '/').split('/');
    mydate1 = new Date(
      Number(parts1[2]),
      Number(parts1[1]) - 1,
      Number(parts1[0]),
    );
  } else {
    mydate1 = date1;
  }
  if (typeof date2 === 'string') {
    let parts2 = replaceAll(date2, '-', '/').split('/');
    // console.log(parts2)
    mydate2 = new Date(
      Number(parts2[2]),
      Number(parts2[1]) - 1,
      Number(parts2[0]),
    );
  } else {
    mydate2 = date2;
  }

  var diff = Math.floor(
    (mydate1.getTime() - mydate2.getTime()) / (1000 * 60 * 60 * 24),
  );

  // console.log(mydate1, mydate2);

  return diff || 0;
}

export function checkHasActByFunc(funcCode: string[], rightList: string[]) {
  if (_.some(funcCode, (o) => o === 'ALL')) return true;
  const _boFunc = _.find(rightList, (o) => _.some(funcCode, (k) => k === o));
  // console.log('checkck',funcCode,rightList,_boFunc)
  if (_boFunc) return true;
  return false;
}

export function renderParams(token: any, params: any, group = 'LIST') {
  let _msg: ParamModel = {
    user: '',
    session: '',
    group: '',
    cmd: '',
    data: {},
  };

  _msg.cmd = params['cmd'] || '';
  _msg.user = token?.user || '';
  _msg.session = token?.sid || '';
  _msg.group = group;

  // remove param cmd
  // delete params['cmd'];

  _msg.data = params;

  return _msg;
}

export function getTextFeeType(feeType: string, feeBase?: string): string {
  if (feeType === 'DAY') {
    if (feeBase === 'DAY') return 'k/ngày';

    return 'k/triệu';
  }

  if (feeType === 'MONTH' || feeType === 'MONTH_DK') return '%/tháng';
  if (feeType === 'WEEK') return '%/tuần';
  if (feeType === 'WEEK_K') return 'k/tuần';

  return '';
}

export function getTextFeeTerm(feeType: string): string {
  if (feeType === 'DAY') {
    return 'ngày';
  }

  if (feeType === 'MONTH' || feeType === 'MONTH_DK') return 'tháng';
  if (feeType === 'WEEK' || feeType === 'WEEK_K') return 'tuần';

  return '';
}

export function setDateBuyType(
  dat1: string,
  count: number,
  feeType: string,
): string {
  // console.log(dat1, count);

  const _date: Date | null = stringToDate(dat1, 'dmy');

  let _count = count;

  if (feeType === 'MONTH' || feeType === 'MONTH_DK') {
    _date?.setMonth(_date.getMonth() - count);
  } else if (feeType === 'WEEK' || feeType === 'WEEK_K') {
    _count = 7 * count;
    _date?.setDate(_date.getDate() - _count);
  } else {
    _date?.setDate(_date.getDate() - _count);
  }
  return formatDate(_date, '/', 'dmy') || '';
}

export function findAll(id: string, items: Array<any>) {
  var i = 0,
    found,
    result: Array<any> = [];

  for (; i < items.length; i++) {
    if (items[i].url === id) {
      result.push(items[i]);
    } else if (_.isArray(items[i].children)) {
      found = findAll(id, items[i].children);
      if (found?.length) {
        result = result.concat(found);
      }
    }
  }
  return result;
}

export function getTypeAccount(code: string): string {
  switch (code) {
    case 'NO':
      return 'NETTED';

    case 'YES':
      return 'NON-NET';

    default:
      return code;
  }
}

export function getOrderStatusName(
  statusCode: string,
  matchVol = 0,
  orderVol = 0,
): string {
  if (statusCode === 'P') {
    return i18n.t('orderStt.cho-khop');
  } else if (statusCode.endsWith('W')) {
    return 'Chờ hủy';
  } else if (statusCode.endsWith('M')) {
    if (StringToInt(matchVol) === StringToInt(orderVol))
      return i18n.t('orderStt.da-khop');

    return i18n.t('orderStt.khop-1-phan');
  } else if (statusCode.endsWith('X')) {
    if (StringToInt(matchVol) > 0) return i18n.t('orderStt.1-phan-da-huy');
    return i18n.t('orderStt.da-huy');
  } else if (statusCode.endsWith('C')) {
    return i18n.t('orderStt.da-sua');
  } else if (statusCode.indexOf('R') > 0) {
    return i18n.t('orderStt.tu-choi');
  }

  return statusCode;
}

export function downloadFile(downloadUrl: any, onSuccess: any, onError: any) {
  console.log('DownloadFile: ' + downloadUrl);
  if (downloadUrl) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', downloadUrl);
    xhr.onload = function () {
      if (xhr.status == 200) {
        onSuccess(xhr.responseText);
      } else {
        onError(xhr.status + ' ' + xhr.statusText);
      }
    };
    xhr.onerror = function (e) {
      console.log(e);
      onError(e);
    };
    xhr.send();
  }
}

export function getQueryParams() {
  var a = window.location.search.substr(1);
  if (a == '') return {};
  var params = a.split('&');
  var b: any = {};
  for (var i = 0; i < params.length; ++i) {
    var p = params[i].split('=', 2);
    if (p.length == 1) b[p[0]] = '';
    else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '));
  }
  return b;
}

export const getLongestDecimalLength = (...numbers: any[]): number =>
  numbers.reduce((previousLength, number) => {
    const numberParts = number.toString().split('.');
    if (numberParts.length <= 1) return previousLength;
    return numberParts[1].length > previousLength
      ? numberParts[1].length
      : previousLength;
  }, 0);

export function checkInvalidSession(rs: any) {
  if (
    rs === 'Invalid session' ||
    rs.includes('InvalidSessionException') ||
    rs.includes('NotLoginException')
  )
    return true;

  return false;
}

export function getMsgByErrorCode(code: string) {
  if (code == '-8005') {
    storages.removeState('isAuthOtp');
  }
  return errMsg[code] ? errMsg[code]?.msg : 'Error';
}

export function _getStatusIndex(st: any, mc = '10') {
  const hours = new Date().getHours();
  if (mc === '10' || mc === '11') {
    // hose
    if (
      (st === 'undefined' || st === 'Undefined' || st == undefined) &&
      hours >= 9 &&
      hours < 10
    )
      return i18n.t('index-list.preopen');

    if (st === 'P') {
      return i18n.t('index-list.preopen');
    }
    if (st === 'O') {
      return i18n.t('index-list.opened');
    }
    if (st === 'I') {
      return i18n.t('index-list.intermission');
    }
    if (st === 'A') {
      return i18n.t('index-list.atc');
    }
    if (st === 'C') {
      return i18n.t('index-list.putThrough');
    }
    return i18n.t('index-list.closed');
  }

  if (mc === '02') {
    // hnx
    if (st === 'O') {
      return i18n.t('index-list.opened');
    }
    if (st === 'I') {
      return i18n.t('index-list.intermission');
    }
    if (st === 'A') {
      return i18n.t('index-list.atc');
    }
    if ((st === 'C' || st === 'K') && hours < 15 && hours > 12) {
      return i18n.t('index-list.txt-plo');
    }
    return i18n.t('index-list.closed');
  }

  if (mc === '03') {
    if (st === 'O') {
      return i18n.t('index-list.opened');
    }
    if (st === 'I') {
      return i18n.t('index-list.intermission');
    }
    return i18n.t('index-list.closed');
  }
  // if (st === 'K' || st === 'C' || st === 'J' || st === 'Undefined') {
  //   return i18n.t('txt-closed');
  // }
  // if (st === 'I') {
  //   return i18n.t('txt-intermission');
  // }
  // return i18n.t('txt-opened');
}

export function getPriceByIndex(stock: any, index: any) {
  console.log(stock, index);
  const hours = new Date().getHours();
  const st = index?.status;
  if (index?.mc === '10') {
    // hose
    if (
      (st === 'undefined' ||
        st === 'Undefined' ||
        st == undefined ||
        st === 'P') &&
      hours >= 9 &&
      hours < 10
    )
      return 'ATO';

    if (st === 'O') {
      return 'MP';
    }
    if (st === 'A') {
      return 'ATC';
    }
    return stock?.f;
  }

  if (index?.mc === '02') {
    // hnx
    if (st === 'O') {
      return 'MTL';
    }
    if (st === 'A') {
      return 'ATC';
    }
    return stock?.f;
  }

  if (index?.mc === '03') {
    return stock?.f;
  }
  return stock?.f || '';
}

export function htmlEscape(text: string) {
  return text
    .replace(/\\/g, '')
    .replace(/&/g, '&amp;')
    .replace(/>/g, '&gt;')
    .replace(/</g, '&lt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;');
}

export function removeAccent(str: string) {
  if (!str) return str;

  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
}

export function getCodeByNameIndex(params: string) {
  const _code =
    params === 'HSX'
      ? '10'
      : params === 'HNX'
        ? '02'
        : params === 'UPCOM'
          ? '03'
          : '10';

  return _code;
}

export function hmsToSeconds(s: string): number {
  var b = s.split(':');
  return +b[0] * 3600 + +b[1] * 60 + (+b[2] || 0);
}

export function formatVolume10(number: string | number | any) {
  let vTemp = StringToInt(number) * 10;
  let vNumber = FormatCurrency(vTemp.toString(), ',', '.');
  return vNumber.substring(0, vNumber.length - 1);
}

function FormatCurrency(num: any, delimitor: any, separate: any) {
  let sign, tail, ret_value;
  num = num.toString().replace(/\$|\,/g, '');
  if (isNaN(num)) num = '0';
  sign = num == (num = Math.abs(num));
  let str = num.toString();
  let arr_str = str.split(separate);
  if (arr_str.length > 1) {
    tail = String(arr_str[1]);
    if (tail.length < 2) {
      tail = tail + '0';
    }
  } else {
    tail = '';
  }
  num = arr_str[0];
  for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
    num =
      num.substring(0, num.length - (4 * i + 3)) +
      delimitor +
      num.substring(num.length - (4 * i + 3));

  if (tail == '') ret_value = (sign ? '' : '-') + num;
  else ret_value = (sign ? '' : '-') + num + separate + tail;
  return ret_value;
}

export function formatRatio(str: string) {
  const _arr: any = str.split(':');
  return _arr[0] * 1 + ':' + _arr[1] * 1;
}

export function _formatDate2(str: string, slack = '/') {
  if (!str || str.length !== 8) return str;

  const year = str.substring(0, 4);
  const month = str.substring(4, 6);
  const day = str.slice(-2);

  return day + slack + month + slack + year;
}

export function daysInMonth(month: any, year: any) {
  return new Date(year, month, 0).getDate();
}

export function daysInWeek(date: string) {
  const st = date.replace(/-/g, '/');
  const s = st.split('/');
  const d = new Date(+s[2], +s[1] - 1, +s[0]);

  const dayInW = d.getDay();
  if (dayInW === 0) return 'CN';

  return 'Th' + (dayInW + 1);
}

export function colorByChange(change: number) {
  if (change > 0) {
    return 'green';
  } else if (change < 0) {
    return 'red';
  }
  return 'yellow';
}

export function isValidURL(string: string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  return res !== null;
}

export function formatDateVietStock(params: any) {
  if (!params) return null;

  var numb = params.match(/\d/g);
  numb = numb.join('');

  const _numb = new Date(StringToInt(numb));

  return formatDate(_numb, '-', 'dmy');
}

export function showPosition(str: string) {
  return str === 'Blank' ? '' : str;
}

export function checkSum(a: string, b: any) {
  if (b == null || b == '' || typeof b === 'undefined') {
    return 'xxx';
  }
  if (typeof b == 'object') {
    let checkSum = a;
    let keysCheckSum = [
      'price',
      'side',
      'volume',
      'account',
      'symbol',
      'refId',
    ];
    keysCheckSum.map((item) => {
      if (item != 'volume') {
        checkSum += b[item];
      } else {
        checkSum += b[item] * 100 + 'vpbs@456';
      }
    });
    return MD5(checkSum).toString();
  } else {
    return MD5(b).toString();
  }
}

export function getRandom() {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < 23; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function _convertTradeTp(orderPrice: string) {
  if (!orderPrice) return '00';
  // log(orderPrice);
  const ordPrice = orderPrice ? (orderPrice + '').toUpperCase() : '';
  if (StringToDouble(orderPrice) > 0) return '01';

  switch (ordPrice) {
    case 'ATO':
      return '02';

    case 'ATC':
      return '03';

    case 'MP':
      return '04';

    case 'MTL':
      return '05';

    case 'MOK':
      return '06';

    case 'MAK':
      return '07';

    case 'PLO':
      return '08';

    default:
      return '00';
  }
}

export function _diff2Date(date1: any, date2: any) {
  let mydate1, mydate2;
  if (typeof date1 === 'string') {
    let parts1: any = date1.split('/');
    mydate1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
  } else {
    mydate1 = date1;
  }
  if (typeof date2 === 'string') {
    let parts2: any = date2.split('/');
    mydate2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);
  } else {
    mydate2 = date2;
  }

  var diff = Math.floor((mydate1 - mydate2) / (1000 * 60 * 60 * 24));

  return diff || 0;
}

export function canDeleteOrder(
  ordrStatTp: string,
  ordrQty: string | number,
  matchedQty: string | number,
) {
  if (ordrStatTp === 'P' || ordrStatTp.endsWith('C')) return true;
  if (
    ordrStatTp.endsWith('M') &&
    StringToInt(matchedQty) < StringToInt(ordrQty)
  )
    return true;

  return false;
}

export function canEditOrder(
  ordrStatTp: string,
  ordrQty: string | number,
  matchedQty: string | number,
) {
  if (ordrStatTp === 'P' || ordrStatTp.endsWith('C')) return true;
  if (
    ordrStatTp.endsWith('M') &&
    StringToInt(matchedQty) < StringToInt(ordrQty)
  )
    return true;

  return false;
}

export function getOrderStatus(
  sttCode: string,
  matchVol = 0,
  orderVol = 0,
  quote = 'Y',
  index = 'HOSE',
) {
  // log(sttCode, index)
  if (sttCode === 'P') {
    return i18n.t('trading.order-book.confirm');
  } else if (sttCode.endsWith('W')) {
    return i18n.t('trading.order-book.wait-destroy');
  } else if (sttCode.endsWith('M')) {
    if (StringToInt(matchVol) === StringToInt(orderVol))
      return i18n.t('trading.order-book.matched');

    return i18n.t('trading.order-book.part-matched');
  } else if (sttCode.endsWith('X')) {
    if (StringToInt(matchVol) > 0)
      return i18n.t('trading.order-book.part-matched-canceled');
    return i18n.t('trading.order-book.canceled');
  } else if (sttCode.endsWith('C')) {
    if (index === 'HNX' && quote === 'G')
      return i18n.t('trading.order-book.admended-2');
    if (index === 'HNX' && quote === 'Y')
      return i18n.t('trading.order-book.confirm');
    return i18n.t('trading.order-book.admended');
  } else if (sttCode.indexOf('R') > 0) {
    return i18n.t('trading.order-book.rejected');
  }

  return sttCode;
}

export function getStatusConditionOrder(st: string) {
  if (st === '0') {
    return i18n.t('trading.order-book.cond-pending');
  } else if (st === '1') {
    return i18n.t('trading.order-book.activated');
  }
  return i18n.t('trading.order-book.canceled');
}

export function _diff2DateSecond(date1: any, date2: any) {
  try {
    let mydate1, mydate2;
    if (typeof date1 === 'string') {
      let parts1: any = date1.split('/');
      mydate1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
    } else {
      mydate1 = date1;
    }
    if (typeof date2 === 'string') {
      let parts2: any = date2.split('/');
      mydate2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);
    } else {
      mydate2 = date2;
    }

    var diff = Math.floor((mydate1 - mydate2) / 1000);

    return diff || 0;
  } catch (error: any) {
    return -1;
  }
}

export function _formatDate3(str: string) {
  const arr = str.split(' ');

  const _arr1 = arr[0].split('-');

  return _arr1[2] + '/' + _arr1[1];
}

export function _formatDate4(str: string) {
  if (!str) return '';

  const arr = str.split(' ');

  const _arr1 = arr[0].split('-');

  return _arr1.reverse().join('/');
}

export function getQ(params: any) {
  let st: any = new Date(params.replace(/-/g, '/'));
  const _month = addZero(st.getMonth() + 1) + '';
  const _year = st.getFullYear();

  // log(_month)

  let _txt = '';
  switch (_month) {
    case '12':
      _txt = 'Q4';
      break;

    case '03':
      _txt = 'Q1';
      break;
    case '06':
      _txt = 'Q2';
      break;
    case '09':
      _txt = 'Q3';
      break;

    default:
      break;
  }

  return _txt + '/' + _year;
}

export function getParameterByName(name: string) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
    results = regex.exec(window.location.search);
  return results == null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function encrypt(word: string, secretKey: string) {
  var key = CryptoJS.enc.Base64.parse(secretKey);
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
}

export function calcRates(arr: number[]): number[] {
  let arrRes: number[] = [],
    numFix = 1;
  arr.forEach((element: number, index: number) => {
    if (index === 0) arrRes.push(element);
    else {
      const numTemp = 1 + element / 100;
      numFix = numFix * numTemp;
      const _sum = (numFix - 1) * 100;
      arrRes.push(Math.round(_sum * 100) / 100);
    }
  });

  return arrRes;
}

export function getActiveNav(linkActive: string, url: string): boolean {
  if (linkActive.startsWith(url + '/') || linkActive === url) return true;
  return false;
}

//corvert number phone
export const hideMiddleDigits = (number: any) => {
  const visibleDigits = 2; // Số chữ số ở đầu và cuối muốn hiển thị
  const hiddenDigitsCount = number.length - visibleDigits * 2;
  const hiddenDigits = '*'.repeat(hiddenDigitsCount);
  return `${number.slice(0, visibleDigits)}${hiddenDigits}${number.slice(-visibleDigits)}`;
};

export const formatAccount = (inputStr: any, preSec: string) => {
  if (!inputStr) return;
  // Bỏ các số 0 ở đầu
  const cleanedInput = inputStr.replace(/^0+/, '');

  // Lấy số cuối cùng để thêm vào sau dấu chấm
  const lastDigit = cleanedInput.slice(-1);

  // Phần còn lại của chuỗi (bỏ số cuối cùng)
  const mainPart = inputStr.slice(0, -1);

  // Thêm tiền tố và hậu tố
  return `${preSec || '059C'}${mainPart}.${lastDigit}`;
};

export const formatAccountLength7 = (inputStr: any) => {
  if (!inputStr) return;
  // Bỏ các số 0 ở đầu
  const cleanedInput = inputStr.replace(/^0+/, '');

  // Lấy số cuối cùng để thêm vào sau dấu chấm
  const lastDigit = cleanedInput.slice(-1);

  // Phần còn lại của chuỗi (bỏ số cuối cùng)
  const mainPart = inputStr.slice(0, -1);

  // Thêm tiền tố và hậu tố
  return `${mainPart}.${lastDigit}`;
};

export function checkInvalidOtp(rs: string) {
  if (rs === 'Invalid OTP' || (rs && rs.toLowerCase().includes('invalid otp')))
    return 'OTP không hợp lệ';

  return rs;
}

//Lấy ngày his trước ngày hiện tại
export function getDateDaysAgo(numDay: number) {
  const currentDate = new Date();
  const millisecondsInDays = (numDay - 1) * 24 * 60 * 60 * 1000;
  const dateDaysAgo = new Date(currentDate.getTime() - millisecondsInDays);
  return dateDaysAgo;
}
// convert url img base64 -> file
export function dataURLtoFile(dataurl: any, filename: any) {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

//hiển thị lại tháng
export function formatMonth(month: string) {
  const m = month.split('/');
  let t = m[0].split('');
  if (t[0] === '0') {
    return 'T' + t[1] + '/' + m[1];
  } else return 'T' + m[0] + '/' + m[1];
}

//Hiển thị tiền
export function formatMoney(value: number) {
  const number = StringToInt(replaceAll(value + '', '-'));
  return number >= 1e9
    ? value / 1e9 + 'tỷ'
    : number >= 1e6
      ? value / 1e6 + 'tr'
      : number >= 1e3
        ? value / 1e3 + 'k'
        : value + 'đ';
}

export function readGroup(group: string) {
  let readDigit = [
    ' Không',
    ' Một',
    ' Hai',
    ' Ba',
    ' Bốn',
    ' Năm',
    ' Sáu',
    ' Bảy',
    ' Tám',
    ' Chín',
  ];
  var temp = '';
  if (group == '000') return '';
  temp = readDigit[parseInt(group.substring(0, 1))] + ' Trăm';
  if (group.substring(1, 2) == '0')
    if (group.substring(2, 3) == '0') return temp;
    else {
      temp += ' Lẻ' + readDigit[parseInt(group.substring(2, 3))];
      return temp;
    }
  else temp += readDigit[parseInt(group.substring(1, 2))] + ' Mươi';
  if (group.substring(2, 3) == '5') temp += ' Lăm';
  else if (group.substring(2, 3) != '0')
    temp += readDigit[parseInt(group.substring(2, 3))];
  return temp;
}

export function numberToWords2(num: string) {
  if (num == null || num == '') return '';
  let temp = '';
  while (num.length < 18) {
    num = '0' + num;
  }
  let g1 = num.substring(0, 3);
  let g2 = num.substring(3, 6);
  let g3 = num.substring(6, 9);
  let g4 = num.substring(9, 12);
  let g5 = num.substring(12, 15);
  let g6 = num.substring(15, 18);
  if (g1 != '000') {
    temp = readGroup(g1);
    temp += ' Triệu';
  }
  if (g2 != '000') {
    temp += readGroup(g2);
    temp += ' Nghìn';
  }
  if (g3 != '000') {
    temp += readGroup(g3);
    temp += ' Tỷ';
  } else if ('' != temp) {
    temp += ' Tỷ';
  }
  if (g4 != '000') {
    temp += readGroup(g4);
    temp += ' Triệu';
  }
  if (g5 != '000') {
    temp += readGroup(g5);
    temp += ' Nghìn';
  }
  temp = temp + readGroup(g6);
  temp = temp.replaceAll('Một Mươi', 'Mười');
  temp = temp.trim();
  temp = temp.replaceAll('Không Trăm', '');
  temp = temp.trim();
  temp = temp.replaceAll('Mười Không', 'Mười');
  temp = temp.trim();
  temp = temp.replaceAll('Mươi Không', 'Mươi');
  temp = temp.trim();
  if (temp.indexOf('Lẻ') == 0) temp = temp.substring(2);
  temp = temp.trim();
  temp = temp.replaceAll('Mươi Một', 'Mươi Mốt');
  temp = temp.trim();
  let result =
    temp.substring(0, 1).toUpperCase() + temp.substring(1).toLowerCase();
  return (result == '' ? 'Không' : result) + ' đồng';
}

const units = [
  'không',
  'một',
  'hai',
  'ba',
  'bốn',
  'năm',
  'sáu',
  'bảy',
  'tám',
  'chín',
];
const tens = [
  'mười',
  'mười một',
  'mười hai',
  'mười ba',
  'mười bốn',
  'mười lăm',
  'mười sáu',
  'mười bảy',
  'mười tám',
  'mười chín',
];
const twenties = [
  '',
  '',
  'hai mươi',
  'ba mươi',
  'bốn mươi',
  'năm mươi',
  'sáu mươi',
  'bảy mươi',
  'tám mươi',
  'chín mươi',
];
function convertHundreds(number: number) {
  if (number === 0) return '';
  let words = '';
  if (Math.floor(number / 100) > 0) {
    words += units[Math.floor(number / 100)] + ' trăm ';
    number %= 100;
  }
  if (number > 0) {
    if (number < 20) {
      words += tens[number - 10];
    } else {
      words += twenties[Math.floor(number / 10)];
      if (number % 10 > 0) {
        words += ' ' + units[number % 10];
      }
    }
  }
  return words.trim();
}
function convertThousands(number: number) {
  if (number === 0) return '';
  let words = '';
  if (Math.floor(number / 1000) > 0) {
    words += convertHundreds(Math.floor(number / 1000)) + ' nghìn ';
    number %= 1000;
  }
  if (number > 0) {
    words += convertHundreds(number);
  }
  return words.trim();
}

function convertMillions(number: number) {
  if (number === 0) return 'không';
  let words = '';
  if (Math.floor(number / 1000000) > 0) {
    words += convertThousands(Math.floor(number / 1000000)) + ' triệu ';
    number %= 1000000;
  }
  if (number > 0) {
    words += convertThousands(number);
  }
  return words.trim();
}

export function numberToWords(num: string) {
  if (!num) return '';
  return convertMillions(Number(num));
}

export function checkFileSizeMb(file: number, size: number) {
  const maxFileSize = size * 1024 * 1024;
  return file > maxFileSize ? true : false;
}

export function getTimeDiffDateCurent(date: any) {
  // ngày truyền vào dd/mm/yyyy

  let parts1 = replaceAll(date, '-', '/').split('/');
  let parts2 = replaceAll(date, '-', '/').split(' ');

  const inputDate: any = new Date(
    parts1[1] + '/' + parts1[0] + '/' + parts1[2],
  );

  const currentDate: any = new Date();

  const diffInMilliseconds = currentDate - inputDate;

  // Chuyển đổi mili giây thành phút
  const diffInMinutes = diffInMilliseconds / (1000 * 60);

  // Chuyển đổi mili giây thành giờ
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

  return diffInMinutes < 60
    ? `${diffInMinutes.toFixed(0)} phút`
    : diffInMinutes > 60 * 24
      ? `Lúc ${parts2[1]}`
      : `${diffInHours.toFixed(0)} giờ`;
}

export function htmlDecode(input: string) {
  var doc = new DOMParser().parseFromString(input, 'text/html');
  return doc.documentElement.textContent;
}

export function getStatusNameRight(status: string) {
  switch (status) {
    case 'CHO_THUC_HIEN':
      return 'Chờ thực hiện';
    case 'DA_HET_HAN':
      return 'Đã hết hạn';
    case 'DA_DANG_KY':
      return 'Đã đăng ký';
    case 'CHUA_DANG_KY':
      return 'Chưa đăng ký';
    case 'CHUA_THUC_HIEN':
      return 'Chưa thực hiện';
    case 'DA_CHUYEN_NHUONG':
      return 'Đã chuyển nhượng';
    default:
      return '';
  }
}

export function getStatusColorRight(status: string) {
  switch (status) {
    case 'CHO_THUC_HIEN':
      return 'r';
    case 'DA_HET_HAN':
      return 'd';
    case 'DA_DANG_KY':
      return 'f';
    case 'CHUA_DANG_KY':
      return '';
    case 'CHUA_THUC_HIEN':
      return '';
    case 'DA_CHUYEN_NHUONG':
      return 'f';
    default:
      return '';
  }
}
